<template>
  <div class="login">
    <el-card style="padding: 24px">
      <h2>Восстановление пароля</h2>
      <el-form
        v-if="!sent"
        class="login-form"
        :model="model"
        :rules="rules"
        ref="form"
        @submit.native.prevent="restore"
      >
        <el-form-item prop="username">
          <el-input
            v-model="model.username"
            placeholder="Логин или email"
            prefix-icon="fas fa-user"
          ></el-input>
        </el-form-item>


        <el-button
            :loading="loading"
            class="login-button"
            type="primary"
            native-type="submit"
            block
        >
          Отправить ссылку
        </el-button>

      </el-form>
      <div v-if="sent">
        Ссылка для восстановления придет Вам на почту
      </div>
    </el-card>
  </div>
</template>

<script>


export default {
  name: 'Restore',
  components: {},

  data() {
    return {
      loading: false,
      sent: false,

      model: {
        username: '',
      },
      rules: {
        username: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'change'},
        ],
      },
    }
  },
  computed: {},

  mounted() {

  },
  methods: {
    restore() {

      this.$refs.form.validate((result) => {
        if (!result) {
          return;
        }

        this.loading = true;
        this.$api.user.sendRestoreLink({
          username: this.model.username,
        })
          .then(data => {
            this.sent = true;
          })
          .finally(() => {
            this.loading = false;
          })

      });
    },

  }
}

</script>

<style lang="scss" scoped>

.login {
  height: 90vh;
  position: relative;
  /*border: 3px solid green;*/
}

.el-card {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


/*.login {*/
/*    flex: 1;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

.login-button {
  width: 100%;
}

.login-form {
  width: 290px;
}

</style>
<style lang="scss">

.login .el-card {
  //margin-top:300px;
  width: 340px;
  //display: flex;
  justify-content: center;
}
</style>
