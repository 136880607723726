import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        search(payload){
            return requestSender('get', 'notification/search', payload);
        },
        getNewNotificationsCount(payload){
            return requestSender('get', 'notification/get-new-notifications-count', payload);
        },
        setSeen(payload){
            return requestSender('post', 'notification/set-seen', payload);
        },
        markAllRead(payload){
            return requestSender('post', 'notification/mark-all-notifications-read', payload);
        },
    }
}